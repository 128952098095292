import React from "react";
import Todo from "./components/todoreact/todo";
// import Temp from "./components/weather/temp";

const App = () => {
  return (
    <>
      <Todo />
    </>
  );
};

export default App;
