import React, { useEffect, useState } from "react";
import "./style.css";

const Todo = () => {
  const getLocalData = ()=>{
    const localList = localStorage.getItem("myToDoList")
    
    if(localList){
      return JSON.parse(localList) ;
    }else{return []}
  }
  const [item, setItem] = useState('');
  const [items, setItems] = useState(getLocalData);
  const [idEdit, setIdEdit] = useState('');
  const [enableEdit, setEnableEdit] = useState(false);

  

  const addItem = () => {
    if(enableEdit){
      const updatedList = items.map((curItem, i)=>{
        if(idEdit===i){
          return item;
        }else{
          return curItem;
        }
      })
      setItems(updatedList)
      setEnableEdit(false)
      setItem('')
      setIdEdit("")
    }else{
      if(item){
        setItems([...items, item]);
        setItem('');
      }else {alert('Please Input')} 
    }
       
  }

  const delItem = (index) => {
    const updatedList = items.filter((curItem, i)=>{
      return index !== i;
    })
    setItems(updatedList)
  }

  const delAll = () => {    
    setItems([])
  }

  const editItem = (index) =>{
    const isItemToEdit = items.filter((curItem, i)=>{
      return index === i;
    })
    setItem(isItemToEdit);
    setIdEdit(index);
    setEnableEdit(true);
  }

  useEffect(()=>{
    localStorage.setItem("myToDoList", JSON.stringify(items))
  },[items]);
  
  return (
    

    <>
      <div className="wrapper">
        <div className="container">
          <figure>
            <img src="./images/todo.svg" alt="todologo" />
            <figcaption>Add Your List Here ✌</figcaption>
          </figure>
          <div className="addItems">
            <input
              value={item}
              onChange={(e)=>setItem(e.target.value)}
              type="text"
              placeholder="✍ Add Item"/>
            <i className="fa" onClick={addItem}>+</i>
          </div>
          {/* show our items  */}
          <div className="showItems">
            {items.map((curItem, i)=>{
              return(
              <div className="eachItem" key={i}>
              <h3>{curItem}</h3>
              <div className="todo-btn">
                <i
                  onClick={()=>editItem(i)}
                  className="editBtn"
                 >E</i>
                <i
                  className="delBtn"
                  onClick={()=>delItem(i)}
                  >Del</i>
              </div>
            </div>
            )
            })}           
                
             
          </div>

          {/* rmeove all button  */}
          <div className="showItems">
            <button
              className="btn" onClick={delAll}>
              <span> Remove All</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Todo;
